import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import AppMenu from './AppMenu';
import ProjectTree from './ProjectTree';
import { selectModeSelected  } from '../../../redux/slices/app/planning.slice';
import { updateUserPreferences } from '../../../redux/slices/app/user.slice';

const ColStyle = createGlobalStyle`
    .sidebar {
        height: calc(100vh - 40px);
    }
`;

const ButtonToggle = styled.button`
    width: 64px;
    height: 30px;
    font-size: 10px;
`;

const activeClasses = 'bg-primary text-secondary-dark font-weight-bold';
const defaultClasses = 'bg-secondary-light text-secondary-dark';

const LeftSidebar = () => {
    const {t} = useTranslation('translation', {keyPrefix: 'baseline_explorer_modal'})
    const dispatch = useDispatch();
    const modeSelected = useSelector(selectModeSelected);

    const changeMode = (mode) => {
            dispatch(updateUserPreferences({ open_mode: mode }));
            if (window.extraViews?.length) {
                window.extraViews.forEach(element => {
                    element.viewInstance.close();
                });
            }
    };

    return (
        <>
            <ColStyle />
            <Col className="flex flex-col sidebar w-full">
                <AppMenu />
                <div className="flex justify-between pt-4">
                    <ButtonToggle
                        type="button"
                        className={modeSelected === 'live' ? activeClasses : defaultClasses}
                        onClick={() => changeMode('live')}
                    >
                        {t('live')}
                    </ButtonToggle>
                    <ButtonToggle
                        type="button"
                        className={modeSelected === 'archive' ? activeClasses : defaultClasses}
                        onClick={() => changeMode('archive')}
                    >
                        {t('archives')}
                    </ButtonToggle>
                    <ButtonToggle
                        type="button"
                        className={modeSelected === 'sandbox' ? activeClasses : defaultClasses}
                        onClick={() => changeMode('sandbox')}
                    >
                        {t('sandbox')}
                    </ButtonToggle>
                </div>

                <ProjectTree />
            </Col>
        </>
    );
};

export default LeftSidebar;
