import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Button, Empty, Spin, Tree } from 'antd';
import { EyeOutlined, EditOutlined, LockOutlined, StarFilled, UserOutlined } from '@ant-design/icons';
import ActivityService from '../../../services/activity.service';
import FolderService from '../../../services/folder.service';
import { PlanningContext } from '../../../contexts/app/PlanningContext';
import AddPlanningModal from '../../plannings/AddPlanningModal';
import ExplorerModal from '../../explorer/ExplorerModal';
import { requestError } from '../../../helpers/notification';
import { ReactComponent as FolderSvg } from '../../../assets/icons/folder.svg';
import { ReactComponent as PlusSvg } from '../../../assets/icons/plus.svg';
import { getPlanningAccess, USER_ACCESS } from '../../../helpers/planning';
import { selectSelectedFolder } from '../../../redux/slices/app/folder.slice';
import { selectLoadingGantt, selectModeSelected, selectPlanningSelected, selectProcessingGantt } from '../../../redux/slices/app/planning.slice';
import { selectCurrentUser } from '../../../redux/slices/app/user.slice';
import { updatePlanning } from '../../../redux/thunks/planning-slice.thunk';

const ProjectList = styled.div`
    background-color: #f8f8f8;
`;

const PlusIcon = styled(PlusSvg)`
    left: 8px;
`;

const TreeStyle = createGlobalStyle`
    .ant-tree {
        height: 100%;
        
        &, .ant-tree-show-line  .ant-tree-switcher {
            background-color: transparent;
        }
        
        .ant-tree-node-content-wrapper.ant-tree-node-selected {
            background-color: #fbbe3f; 
        }
    }
`;

const ProjectTree = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        updatedFolderBroadcast,
        updatedPlanningTreeBroadcast,
    } = useContext(PlanningContext);
    const loadingGantt = useSelector(selectLoadingGantt);
    const processingGantt = useSelector(selectProcessingGantt);
    const folderSelected = useSelector(selectSelectedFolder);
    const modeSelected = useSelector(selectModeSelected);
    const planningSelected = useSelector(selectPlanningSelected);
    const currentUser = useSelector(selectCurrentUser)
    const [treeData, setTreeData] = useState([]);
    const [canAddPlanning, setCanAddPlanning] = useState(!currentUser.readOnly);
    // eslint-disable-next-line
    const [lastRequestTime, setLastRequestTime] = useState(moment().valueOf());
    const addPlanningModalRef = useRef();
    const explorerModalRef = useRef();
    
    const delayBetweenRequests = 60000;

    const getPermissionIcon = (planningAccess) => {
        let icon = <></>;
        switch (planningAccess) {
            case USER_ACCESS.READ:
                icon = <EyeOutlined className="mr-2" />;
                break;
            case USER_ACCESS.READWRITE:
                icon = <EditOutlined className="mr-2" />;
                break;
            case USER_ACCESS.ADMIN:
                icon = <EditOutlined className="mr-2" />;
                break;
            case USER_ACCESS.NONE:
                icon = <LockOutlined className="mr-2" />;
                break;
            case USER_ACCESS.OWNER:
                icon = <UserOutlined className="mr-2" />;
                break;

            default:
                break;
        }
        return icon;
    };


    /**
     * Build subactivities level for a planning.
     * @param planning
     * @param planningAccess
     * @returns {Promise<*[]>}
     */
    const buildSubActivityPlanning = async (planning, planningAccess) => {
        let subActivities = [];
        if (planningAccess !== USER_ACCESS.NONE) {
            try {
                const rootActivity = await ActivityService.treeActivities(planning.rootActivityId);
                if (rootActivity && rootActivity.subActivities.length) {
                    subActivities = rootActivity.subActivities.map((subActivity) => ({
                        key: `activity-${subActivity.id}`,
                        title: subActivity.name,
                        hasChildren: subActivity.subActivities.length > 0,
                        children: [],
                        leaf: true,
                        activityId: subActivity.id,
                        planningData: {
                            ...planning,
                            summaryId: subActivity.id,
                        },
                    }));
                }
            } catch (error) {
                requestError(error, `${planning.name}`);
                Sentry.captureException(error);
            }
        }
        return subActivities;
    };

    /**
     * Load subActivities for a planning and update view.
     * Only if planning never loaded before.
     * @param node
     */
    const loadPlanningTreeDataAsync = async (planning) => {
        const nTreeData = [...treeData];
        const index = nTreeData.findIndex((node) => node.key === planning.key);
        if (!planning.loaded) {
            await buildSubActivityPlanning(planning.planningData, planning.access).then((response) => {
                nTreeData[index].children = response;
                nTreeData[index].hasChildren = nTreeData[index].children.length > 0;
                nTreeData[index].loaded = true;

                return new Promise((resolve) => {
                    setTreeData(nTreeData);
                    resolve();
                });
            });
        }
    };

    /**
     * Build a planning without loading subactivities level.
     * @param planning
     * @returns {Promise<{loaded: boolean, access: string, children: [{}], hasChildren: boolean, planningData, disabled: boolean, title, key: string, isFavorite: (boolean|*)}>}
     */
    const buildPlanning = async (planning) => {
        const planningAccess = getPlanningAccess(planning, currentUser);
        return {
            key: `planning-${planning.id}`,
            title: planning.name,
            planningData: planning,
            hasChildren: true,
            children: [],
            isFavorite: planning.favorite,
            access: planningAccess,
            disabled: planningAccess === USER_ACCESS.NONE,
            loaded: false,
        };
    };

    /**
     * Build all planning tree.
     * @param folder
     * @returns {Promise<{[p: string]: PromiseSettledResult<*>}>}
     */
    /* eslint-disable */
    const buildPlanningTreeData = async (folder) => {
        let plannings;
        switch (modeSelected) {
            case 'archive':
                plannings = await FolderService.listArchives(folder.id);
                break;
            case 'sandbox':
                plannings = await FolderService.listSandboxPlannings();
                break;
            default:
                plannings = await FolderService.listPlannings(folder.id);
        }

        return Promise.allSettled((plannings || []).map(buildPlanning));
    };

    useEffect(() => {
        const onFolderSelectedChange = async () => {
            if (folderSelected) {
                FolderService.getUserPermissionOnFolder(folderSelected.id)
                    .then((permission) => {
                        if (permission)
                        setCanAddPlanning(currentUser.isAdmin || (!currentUser.readOnly && permission === USER_ACCESS.READWRITE));
                    })
                    .catch(() => {
                        setCanAddPlanning(false);
                    });
                buildPlanningTreeData(folderSelected).then((data) => {
                    const planningList = data
                        .map((response) => {
                            if ('value' in response) {
                                return response.value;
                            }
                            requestError(response.reason, t('general.generic_error'));
                            return null;
                        })
                        .filter((planning) => planning !== null);
                    setTreeData(planningList);
                });
            }
        };
        onFolderSelectedChange();
    }, [folderSelected, modeSelected]);

    useEffect(() => {
        const onBroadcast = async () => {
            if (updatedFolderBroadcast && folderSelected) {
                if (
                    updatedFolderBroadcast.type === 'Folder' &&
                    Number(updatedFolderBroadcast.id) === folderSelected.id
                ) {
                    if (updatedFolderBroadcast.updatedData) {
                        dispatch(setFolderSelected(updatedFolderBroadcast.updatedData));
                    } else {
                        // deleted
                        dispatch(setFolderSelected(null));
                    }
                }
                if (updatedFolderBroadcast.type === 'Planning') {
                    const indexPlanning = treeData.findIndex((i) => i.planningData.id === +updatedFolderBroadcast.id);
                    console.log("🚀 ~ onBroadcast ~ indexPlanning:", indexPlanning)
                    const cloneTreeData = [...treeData];
                    if (updatedFolderBroadcast.updatedData) {
                        const planningAccess = getPlanningAccess(updatedFolderBroadcast.updatedData, currentUser);
                        if (indexPlanning !== -1) {
                            cloneTreeData[indexPlanning] = {
                                ...cloneTreeData[indexPlanning],
                                title: updatedFolderBroadcast.updatedData.name,
                                planningData: updatedFolderBroadcast.updatedData,
                                isFavorite: updatedFolderBroadcast.updatedData.favorite,
                                access: planningAccess,
                                disabled: planningAccess === USER_ACCESS.NONE,
                            };
                            // reset tree data
                            setTreeData(cloneTreeData);
                            return;
                        }
                        if (
                            indexPlanning === -1 &&
                            +updatedFolderBroadcast.updatedData.folderId === +folderSelected.id
                        ) {
                            // new

                            cloneTreeData.push({
                                key: `planning-${updatedFolderBroadcast.updatedData.id}`,
                                title: updatedFolderBroadcast.updatedData.name,
                                planningData: updatedFolderBroadcast.updatedData,
                                isFavorite: false,
                                children: [],
                                access: planningAccess,
                                disabled: planningAccess === USER_ACCESS.NONE,
                            });
                            setTreeData(cloneTreeData);
                            // if empty folder before
                            if (!planningSelected && planningAccess !== USER_ACCESS.NONE) {
                                dispatch(updatePlanning({planning: updatedFolderBroadcast.updatedData}));
                            }
                            return;
                        }
                    }
                    if (updatedFolderBroadcast.updatedData === null && indexPlanning !== -1) {
                        // deleted
                        if (+planningSelected?.id === +updatedFolderBroadcast.id && treeData.length > 0) {
                            console.log('planning has been deleted');
                            // remove from favorite list if favorite
                            // if (updatedFolderBroadcast.updateData.isFavorite) {

                            //     UserService.removePlannigFromfavorite(planningSelected.id)
                            // }

                            cloneTreeData.splice(indexPlanning, 1);
                            if (cloneTreeData[0]) {
                                dispath(updatePlanning({planning:cloneTreeData[0].planningData}));
                            } else {
                                // remove from preference
                               dispatch(updatePlanning({planning: null}));
                            }
                            setTreeData(cloneTreeData);
                        } else {
                            // if (cloneTreeData[indexPlanning].isFavorite) {
                            //     UserService.removePlannigFromfavorite(cloneTreeData[indexPlanning].id)
                            // }
                            cloneTreeData.splice(indexPlanning, 1);
                            setTreeData(cloneTreeData);
                        }
                    }
                }
            }
        };
        onBroadcast();
    }, [updatedFolderBroadcast]);

    // updating tree content when an activity is updated (to check first level activities changes)
    useEffect(() => {
        setLastRequestTime((old) => {
            const currentTime = moment().valueOf();
            const lastTime = old;
            if (currentTime - lastTime > delayBetweenRequests && updatedPlanningTreeBroadcast) {
                const planningRootActivityId = updatedPlanningTreeBroadcast.rootId;
                FolderService.getPlanningByRootActivityId(planningRootActivityId).then((planningToUpdate) => {
                    const indexPlanning = treeData.findIndex((i) => i.planningData.id === +planningToUpdate);
                    const cloneTreeData = [...treeData];
                    if (indexPlanning !== -1) {
                        const { planningData } = cloneTreeData[indexPlanning];
                        let subActivities = [];
                        ActivityService.treeActivities(planningRootActivityId).then((rootActivity) => {
                            if (rootActivity && rootActivity.subActivities.length) {
                                subActivities = rootActivity.subActivities.map((subActivity) => ({
                                    key: `activity-${subActivity.id}`,
                                    title: subActivity.name,
                                    hasChildren: subActivity.subActivities.length > 0,
                                    leaf: true,
                                    activityId: subActivity.id,
                                    planningData: {
                                        ...planningData,
                                        summaryId: subActivity.id,
                                    },
                                }));
                            }
                            cloneTreeData[indexPlanning] = {
                                ...cloneTreeData[indexPlanning],
                                children: subActivities,
                            };
                            // reset tree data
                            setTreeData(cloneTreeData);
                        });
                    }
                });
                return currentTime;
            }
            return lastTime;
        });
    }, [updatedPlanningTreeBroadcast]);

    return (
        <>
            <TreeStyle />
            <button
                type="button"
                id="explorer-modal"
                className={`mt-4 px-4 py-2 rounded-sm text-left text-secondary-dark font-sans flex items-center ${
                    modeSelected === 'sandbox' ? 'bg-secondary' : 'bg-primary'
                } ${folderSelected ? '' : 'animate-pulse'}
                
                `}
                onClick={explorerModalRef.current && explorerModalRef.current.openModal}
                // disabled={modeSelected === 'sandbox'}
            >
                <FolderSvg className="stroke-current" />
                <span className="ml-1">
                    {folderSelected ? folderSelected.name : t('explorer_modal.folder_explorer')}
                </span>
            </button>
            <Button
                type="secondary"
                className="relative block w-full flex items-center font-bold justify-center mt-3 mb-2 cursor-pointer overflow-hidden"
                style={{ borderColor: '#000' }}
                onClick={addPlanningModalRef.current && addPlanningModalRef.current.openModal}
                disabled={!folderSelected || modeSelected === 'archive' || !canAddPlanning}
            >
                <PlusIcon className="absolute left-0 ml-3" /> {t('modal_add_planning.add_planning')}
            </Button>
            <div className="h-full" style={{ overflowY: 'auto', backgroundColor: '#f8f8f8' }}>
                {folderSelected ? (
                    <ProjectList className="flex-1 mt-4 rounded-sm p-2 flex flex-col">
                        <Tree
                            treeData={treeData}
                            showLine={{ showLeafIcon: false }}
                            loadData={loadPlanningTreeDataAsync}
                            className="mb-4"
                            selectedKeys={[
                                planningSelected?.summaryId
                                    ? `activity-${planningSelected?.summaryId}`
                                    : `planning-${planningSelected?.id}`,
                            ]}
                            titleRender={(data) =>
                                data.isFavorite ? (
                                    <div className="flex items-center">
                                        {data.access && getPermissionIcon(data.access)}
                                        {data.title}
                                        <StarFilled className="ml-2" />
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        {data.access && getPermissionIcon(data.access)}
                                        {data.title}
                                    </div>
                                )
                            }
                            onSelect={(nodeKey, event) => {
                                if (loadingGantt) {
                                    return;
                                }

                                if (!('planningData' in event.node)) {
                                    return;
                                }

                                if (event.node.planningData.summaryId) {
                                    if (event.node.planningData.summaryId === planningSelected?.summaryId) {
                                        return;
                                    }
                                } else if (!planningSelected?.summaryId) {
                                    if (event.node.planningData.id === planningSelected?.id) {
                                        return;
                                    }
                                }
                                console.log('planning select', event.node.planningData)
                                dispatch(updatePlanning({planning: event.node.planningData, refetch: !event.node.planningData.summaryId}));
                                if (window.extraViews?.length) {
                                    window.extraViews.forEach((element) => {
                                        element.viewInstance.close();
                                    });
                                }
                            }}
                            disabled={loadingGantt || processingGantt}
                        />
                    </ProjectList>
                ) : (
                    <div className="h-full w-full flex justify-between items-center">
                        <Spin className="w-full" />
                    </div>
                )}
                {folderSelected && treeData.length === 0 && (
                    <Empty description={t('sidebar.no_planning')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>

            <AddPlanningModal ref={addPlanningModalRef} parent={folderSelected ? folderSelected.id : null} />
            <ExplorerModal ref={explorerModalRef} />
        </>
    );
};
export default ProjectTree;
